import {http} from "../../config/http.js"
import _ from "lodash";


const state = {
    requisites: [],
    unsavedRequisites: []
};

const mutations = {
    SET_REQUISITES(state, fields) {
        state.requisites = fields
    },
    ADD_REQUISITE(state, newField) {
        state.requisites.push(newField)
    },
    MODIFIED_REQUISITE(state, requisiteToChange) {
        const index = _.findIndex(state.requisites, _.pick(requisiteToChange, "_id"));
        state.requisites.splice(index, 1, requisiteToChange);
    },
    REMOVE_REQUISITE(state, requisiteToDelete) {
        state.requisites = _.filter(state.requisites, (requisite) => {
            return requisite._id !== requisiteToDelete._id;
        })
    },
    SET_UNSAVED_REQUISITES(state, unsavedRequisites) {
        state.unsavedRequisites = unsavedRequisites;
    },
};

const actions = {
    loadRequisites({commit}) {
        return http().get('/requisites')
            .then(r => r.data.data)
            .then(fields => {
                commit('SET_REQUISITES', fields)
            })
    },
    addRequisite({commit, dispatch}, newFieldData) {
        return http().post('/requisites', newFieldData)
            .then(r => r.data.data)
            .then(response => {
                commit('ADD_REQUISITE', response);
                dispatch('loadStatus');
                dispatch('loadStock');
            })
    },
    editRequisite({commit, dispatch}, modifiedData) {
        return http().put('/requisites/' + modifiedData._id, modifiedData)
            .then(r => r.data.data)
            .then(response => {
                commit('MODIFIED_REQUISITE', response)
                dispatch('loadStatus');
                dispatch('loadStock');
            })
    },
    removeRequisite({commit, dispatch}, requisite) {
        return http().delete('/requisites/' + requisite._id)
            .then(() => {
                commit('REMOVE_REQUISITE', requisite)
                dispatch('findUnsavedRequisites');
                dispatch('loadStatus');
                dispatch('loadStock');
            })
    },
    // eslint-disable-next-line no-unused-vars
    refineRequisiteAmounts({dispatch}, {data}) {
        return http().post('/requisites/refine', data)
    },
    findUnsavedRequisites({commit}) {
        return http().get('/requisites/unsaved-requisites')
            .then(r => r.data.data)
            .then(response => {
                commit('SET_UNSAVED_REQUISITES', response)
            })
    },
    saveUnsavedReqisites({dispatch}, requisitesToStore) {
        return http().post('/requisites/unsaved-requisites', requisitesToStore)
            .then(() => {
                dispatch('loadRequisites');
                dispatch('findUnsavedRequisites');
            })
    },
};

const getters = {
    getRequisites: state => type => {
        return _.filter(state.requisites, requisite => requisite.type === type)
    },
    getRequisitesByType: state => types => {
        return _.filter(state.requisites, requisite => _.includes(types, requisite.type))
    },
    getAllRequisites: state => {
        return state.requisites
    },
    hasAnyRequisites: state => {
        return state.requisites && state.requisites.length > 0;
    },
    requisite: state => id => {
        return state.requisites.find(requisite => requisite._id === id);
    },
    getUnsavedRequisitesFor: state => type => {
        return _.filter(state.unsavedRequisites, requisite => requisite.type === type);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}